*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: white;
}

.App {
  display: flex;
  flex-flow: column;
  align-items: center;

  font-family: leander-script-pro, sans-serif;
  color: #a87e00;

  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-flow: column;
  text-align: center;
  user-select: none;
  overflow-y: auto;
}

.top {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  padding-bottom: 124px;
  align-items: center;
  flex: 0 0 100vh;
}

.flowers {
  width: 100%;
  height: 50vmin;
  background-image: url("./wf.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  font-size: 100px;
  line-height: 77px;
  margin-bottom: 8px;
  padding: 0 24px;
  flex: 1 1 auto;
}

h2 {
  font-size: 54px;
  line-height: 54px;
  margin-bottom: 8px;
}

h3 {
  color: #333;
  font-size: 32px;
  margin-bottom: 8px;
  font-family: futura-pt, sans-serif;
  letter-spacing: 1.5px;
  font-weight: 700;
  font-style: normal;
}

.map-container {
  margin-top: 24px;
  height: 400px;
  width: 600px;
}

input {
  font-family: futura-pt, sans-serif;
  letter-spacing: 1.5px;
  font-weight: 400;
  font-style: normal;
  margin-top: 100px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  padding: 16px;
  text-align: center;
  width: 340px;
  box-shadow: -1px 1px 10px 3px rgb(100 100 100 / 8%) inset;
}

p {
  font-family: futura-pt, sans-serif;
  letter-spacing: 1.5px;
  font-size: 22px;
  max-width: 1200px;
  overflow-wrap: break-word;
}

a {
  font-family: futura-pt, sans-serif;
  letter-spacing: 1px;
  font-size: 22px;
  color: #333;
}

img {
  width: 100%;
  pointer-events: none;
  margin: 24px 0;
  max-width: 1200px;
}

.location {
  display: flex;
  flex-flow: column;
  padding: 24px 32px;
}

@keyframes jumpInfinite {
  0% {
    top: 0;
  }
  50% {
    top: 20px;
  }
  100% {
    top: 0;
  }
}

.arrow-button {
  width: 100px;
  height: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
}

.down-arrow {
  position: relative;
  top: 0;
  left: 0;
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  transform: scale(1.2) rotate(90deg);
  transition: transform 0.2s, height 0.2s;
  pointer-events: none;
  border-color: #a87e00;
}

.down-arrow.show {
  transform: scale(1.2) rotate(0);
  animation: jumpInfinite 1.5s infinite;
  height: 30px;
  pointer-events: all;
}

.down-arrow.show:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 2px;
  transform: rotateZ(45deg);
  border-color: #a87e00;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.6);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #a87e00;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #a87e00 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

hr {
  width: 40px;
  height: 6px;
  min-height: 6px;
  border: none;
  background-color: #a87e00;
  border-radius: 8px;
  margin: 48px 0;
}

.list {
  display: grid;
  grid-template-columns: 1fr 3fr;
  text-align: left;
  column-gap: 16px;
}

section {
  max-width: 100%;
}

section:not(.top) {
  padding: 0 16px;
}
